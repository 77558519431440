body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ropa Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./assets/images/donor.jpg") no-repeat center center fixed;
  background-size: cover;
  object-fit: cover;
}

/* iOS specific fix */
@supports (-webkit-touch-callout: none) {
  body {
    background-attachment: scroll;
    background-position: center center;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Righteous', sans-serif;
}
.heading {
  font-family: "Righteous", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,900;1,400;1,900&display=swap');
*{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: "Righteous", sans-serif !important;
}

body {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./assets/images/donor.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  object-fit: cover;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./assets/images/donor.jpg") no-repeat center center;
  background-size: cover;
  z-index: -1;
  pointer-events: none;
}

@supports (-webkit-touch-callout: none) {
  body::before {
    background-attachment: scroll;
  }
}

#header{
  margin:20px;
  margin-top: 50px;
}
#header>h1{
  text-align:center;
  font-size: 5vw;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    font-size: 8vw;
    font-weight: 500;
  }
}

#header>p{
  text-align:center;
  font-size:1.5rem;
  font-style:italic;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 20px;
  overflow: hidden;
}

.card {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  max-width: 350px;
  margin: 20px;
  border-radius: 14px;
}

.card > div {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 14px;
}

.card-image {
  width: 100%;
  height: 250px;
  border-radius: 14px;
}

.card-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  border-radius: 14px;
}

.card-text {
  margin: -20px auto;
  height: auto;
  width: 90%;
  background-color: #1D1C20;
  color: #fff;
  padding: 20px;
  border-radius: 14px;
}

.card-meal-type {
  font-style: italic;
}

.card-title {
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 5px;
}

.card-body {
  font-size: 1rem;
}

.card-price {
  width: auto;
  height: auto;
  background-color: #970C0A;
  color: #fff;
  margin-left: auto;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  padding: 10px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    margin-bottom: 30px;
  }

  .card-image {
    width: 100%;
    height: 250px;
  }

  .card-text {
    width: 90%;
    height: auto;
    margin: -20px auto;
    padding: 15px;
  }
}

/* Add some padding to the top of your main content to account for the fixed header */
main {
  padding-top: 80px;
}

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* Update existing styles */
#header {
  margin: 20px;
  margin-top: 50px;
  text-align: center;
}

.gradient-text {
  font-size: 5vw;
  background: linear-gradient(135deg, #FF4D4D, #F9CB28);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}

.subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  letter-spacing: 2px;
  opacity: 0.9;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 40px 20px;
}

.card {
  background: rgba(29, 28, 32, 0.8);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 77, 77, 0.3);
}

.card-image {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card:hover .card-image img {
  transform: scale(1.1);
}

.location-badge {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  backdrop-filter: blur(5px);
  font-size: 0.9rem;
}

.card-text {
  padding: 25px;
}

.card-header {
  margin-bottom: 15px;
}

.card-title {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 10px;
  background: linear-gradient(135deg, #FF4D4D, #F9CB28);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-info {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.contact-link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(255, 255, 255, 0.8) !important;
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.contact-link:hover {
  color: #FF4D4D !important;
}

.card-body {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 0.95rem;
}

.visit-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background: linear-gradient(135deg, rgba(255, 77, 77, 0.1), rgba(249, 203, 40, 0.1));
  color: white !important;
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.visit-button:hover {
  background: linear-gradient(135deg, #FF4D4D, #F9CB28);
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .gradient-text {
    font-size: 8vw;
  }

  .container {
    grid-template-columns: 1fr;
  }

  .card {
    margin: 0 10px;
  }
}

.restaurants-section {
  padding: 80px 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.section-title {
  font-size: clamp(2rem, 4vw, 3rem) !important;
  text-align: center;
  margin-bottom: 0.5rem !important;
  background: linear-gradient(135deg, #FF4D4D, #F9CB28);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700 !important;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.section-subtitle {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: clamp(1rem, 2vw, 1.2rem) !important;
  margin-bottom: 4rem !important;
  letter-spacing: 1px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.restaurant-card {
  perspective: 1500px;
  height: min(500px, 95vh);
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.restaurant-card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 20px;
  overflow: hidden;
}

.card-front {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card-back {
  background: linear-gradient(135deg, rgba(255, 77, 77, 0.95), rgba(249, 203, 40, 0.95));
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper {
  height: clamp(180px, 30vh, 250px);
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.restaurant-card:hover .image-wrapper img {
  transform: scale(1.1);
}

.card-content {
  padding: 1.5rem;
  position: relative;
  height: calc(100% - clamp(180px, 30vh, 250px));
  display: flex;
  flex-direction: column;
}

.restaurant-name {
  font-size: clamp(1.2rem, 2.5vw, 1.5rem) !important;
  margin-bottom: 0.8rem !important;
  background: linear-gradient(135deg, #FF4D4D, #F9CB28);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

.description {
  color: rgba(255, 255, 255, 0.8) !important;
  line-height: 1.5 !important;
  font-size: clamp(0.85rem, 2vw, 0.95rem) !important;
  margin-bottom: 1rem !important;
}

.flip-hint {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s ease;
}

.flip-hint svg {
  transition: transform 0.3s ease;
}

.restaurant-card:hover .flip-hint {
  color: #FF4D4D;
  transform: translateX(5px);
}

.restaurant-card:hover .flip-hint svg {
  transform: rotate(-90deg);
}

.contact-wrapper {
  padding: 2rem;
  text-align: center;
  width: 100%;
}

.contact-title {
  color: white !important;
  font-size: 1.5rem !important;
  margin-bottom: 2rem !important;
  font-weight: 600 !important;
}

.contact-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
  padding: clamp(0.6rem, 2vw, 1rem) !important;
  margin: 1rem 0 !important;
  border-radius: 12px !important;
  text-decoration: none !important;
  transition: all 0.3s ease !important;
  font-size: clamp(0.85rem, 2vw, 1rem) !important;
  font-weight: 500 !important;
  width: 100% !important;
}

.phone-button {
  background: rgba(0, 0, 0, 0.2) !important;
  color: white !important;
}

.website-button {
  background: white !important;
  color: #FF4D4D !important;
}

.phone-button:hover {
  background: rgba(0, 0, 0, 0.3) !important;
  transform: translateY(-3px);
}

.website-button:hover {
  background: rgba(255, 255, 255, 0.9) !important;
  transform: translateY(-3px);
}

@media (max-width: 1200px) {
  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.2rem;
  }
}

@media (max-width: 768px) {
  .restaurants-section {
    padding: 30px 15px;
  }

  .section-subtitle {
    font-size: 0.9rem !important;
    margin-bottom: 2rem !important;
  }

  .restaurant-card {
    height: 420px;
  }

  .card-content {
    padding: 1.2rem;
  }

  .contact-wrapper {
    padding: 1.5rem;
  }

  .contact-title {
    font-size: 1.2rem !important;
    margin-bottom: 1.5rem !important;
  }

  .flip-hint {
    right: 1rem;
    bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .restaurant-card {
    height: 400px;
  }

  .description {
    font-size: 0.85rem !important;
    margin-bottom: 0.8rem !important;
  }

  .flip-hint {
    font-size: 0.75rem !important;
  }
}

/* Add smooth transitions for all screen sizes */
@media (hover: hover) {
  .restaurant-card:hover .card-inner {
    transform: rotateY(180deg);
  }
}

/* Touch device optimization */
@media (hover: none) {
  .restaurant-card {
    cursor: pointer;
  }

  .card-inner.touched {
    transform: rotateY(180deg);
  }

  .info-button {
    display: none !important;
  }
}