#app {
  * {
    position: relative;
    box-sizing: border-box;
  }

  .iframely-embed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: white;
  }

  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  padding-bottom: 4vmin;
  height: 90vh;
  width: 100%;
  color: #000000de;
  overflow: hidden; /* Prevent overflow */

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    height: auto; /* Adjust height for mobile view */
  }

  .title {
    padding-left: 1em;
    grid-column: 1 / -1;
    grid-row: 1;
    font-weight: 600;
    font-size: 5vw;
    width: 100%;
    z-index: 2;

    @media (max-width: 768px) {
      padding-left: 0;
      font-size: 10vw;
      font-weight: 700;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50vh; /* Reduce height for mobile view */
    }

    > .title-inner {
      display: inline-block;
    }
  }

  @keyframes text-clip {
    from {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  @keyframes outer-left {
    from {
      transform: translateX(50%);
    }
    to {
      transform: none;
    }
  }

  @keyframes inner-left {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: none;
    }
  }

  .cafe,
  .mozart {
    animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
  }

  .title-inner {
    display: inline-block;
    animation: inner-left 1s 1s ease both;
  }

  .cafe-inner {
    display: inline-block;
    animation:
      inner-left 1s 1s ease both,
      text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
  }

  .mozart-inner {
    animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
  }

  .title {
    animation: outer-left 1s 1s ease both;
  }

  .cafe {
    > .cafe-inner {
      display: inline-block;
    }
  }

  .mozart {
    display: inline-block;
  }

  .image {
    grid-row: 1;
    grid-column: 2; /* Move image to the right side */
    opacity: 1;
    animation: image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 2s backwards;

    @keyframes image-in {
      from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      }
      to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }

    img {
      display: block;
      width: 100%; /* Reduced size */
      height: auto;
    }

    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      grid-column: 1;
      display: flex;
      justify-content: center;
    }
  }

  body:active * {
    animation: none !important;
  }
}

@media (max-width: 768px) {
  .carousel-button.prev {
    left: 0 !important;
  }
  .carousel-button.next {
    right: 0 !important;
  }
}

.hero-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  // background-color: #0a0a0a;

  .hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.519) 0%,
      rgba(0, 0, 0, 0.332) 50%,
      rgba(0, 0, 0, 0.336) 100%
    );
  }

  .animated-shapes {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .shape {
      position: absolute;
      border-radius: 50%;
      background: linear-gradient(45deg, rgba(255, 51, 102, 0.1), rgba(255, 87, 51, 0.1));
      animation: float 20s infinite;

      &-1 {
        width: 300px;
        height: 300px;
        top: -150px;
        left: -150px;
      }

      &-2 {
        width: 500px;
        height: 500px;
        top: 50%;
        right: -250px;
        animation-delay: -5s;
      }

      &-3 {
        width: 400px;
        height: 400px;
        bottom: -200px;
        left: 30%;
        animation-delay: -10s;
      }
    }
  }

  .content-wrapper {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(20px);
    
    &.animate-in {
      animation: fadeInUp 1s forwards;
    }
  }

  .title-inner {
    text-align: center;
    padding: 2rem;
    max-width: 900px;
    position: relative;
  }

  .highlight-bar {
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #ff3366, #ff5733);
    margin: 0 auto 2rem;
    border-radius: 2px;
  }

  .cafe-inner {
    font-size: 5rem;
    font-weight: 800;
    margin-bottom: 2rem;
    position: relative;
    
    .text-gradient {
      background: linear-gradient(45deg, #ff3366, #ff5733);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      display: inline-block;
      filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
    }

    @media (max-width: 768px) {
      font-size: 3.5rem;
    }
  }

  .mozart-inner {
    font-size: 1.8rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 1.6;
    margin-bottom: 3rem;
    opacity: 0.9;
    
    .highlight {
      color: #ff3366;
      font-weight: 600;
    }

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .cta-group {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    margin-bottom: 3rem;
  }

  .cta-button {
    padding: 1rem 2.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    
    &.primary {
      background: linear-gradient(45deg, #ff3366, #ff5733);
      color: white;
      border: none;
      box-shadow: 0 4px 15px rgba(255, 51, 102, 0.3);

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 20px rgba(255, 51, 102, 0.4);
      }
    }

    &.secondary {
      background: transparent;
      color: white;
      border: 2px solid rgba(255, 255, 255, 0.3);

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .stats-container {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin-top: 2rem;

    .stat-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .stat-number {
        font-size: 2.5rem;
        font-weight: 700;
        color: #ff3366;
        margin-bottom: 0.5rem;
      }

      .stat-label {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.8);
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-30px) rotate(180deg);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

$red: rgba(97, 89, 86, 0.25);
$black: #191919;

.container {
  height: 100%;
  .wrapper {
    .home {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      p {
        font-size: 0.75rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      h5 {
        margin-top: 260px;
        font-size: 24px;
        font-weight: 600;
        padding-right: 360px;
      }
    }
  }
}

header {
  height: 100px;
  .inner-header {
    position: relative;
    z-index: 10;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo a {
      font-weight: 700;
      font-size: 1rem;
      text-decoration: none;
      color: $black;
    }
    .menu {
      button {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 0.8rem;
        mix-blend-mode: difference;
      }
    }
  }
  .hamburger-menu {
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    .menu-secondary-background-color {
      background: $black;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .menu-layer {
      position: relative;
      background: $red;
      height: 100%;
      overflow: hidden;
      .menu-city-background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
        animation-name: backgroundEffect;
        animation-duration: 30s;
        animation-iteration-count: infinite;
      }
      .wrapper {
        display: flex;
        position: relative;
        height: 100%;
        align-items: center;
        justify-content: center;
        .menu-links {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          nav {
            display: block;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                font-size: 1.5rem;
                font-weight: 700;
                cursor: pointer;
                height: auto;
                overflow: hidden;
                position: relative;
                width: auto;
                a {
                  position: relative;
                  color: #fff;
                  text-decoration: none;
                  &:hover {
                    color: $black;
                  }
                }
              }
            }
          }
        }
        .locations {
          position: absolute;
          bottom: -80px;
          color: #fff;
          margin-top: 16px;
          font-size: 0.8rem;
          font-weight: 600;
          span {
            cursor: pointer;
            &:first-child {
              margin-left: 64px;
            }
            font-weight: 400;
            margin: 0 32px;
            transition: 0.3s ease-in-out;
            &:hover {
              color: #fff;
              background: $black;
              padding: 8px 24px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}